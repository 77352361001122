<template>
  <div class="download_warp">
    <p class="download_tips" v-if="istips">可点击右上角选择浏览器打开 ↑</p>
    <div>
      <img
        class="download_logo"
        :src="require('@/assets/img/login/logo.png')"
        alt=""
      />
    </div>
    <div>
      <img
        class="download_title"
        :src="require('@/assets/img/login/008.png')"
        alt=""
      />
    </div>

    <!-- <div class="download_title">星火源</div>
    <p class="download_content">环境监控数据贴身管家</p> -->
    <div>
      <img
        class="download_img"
        :src="require('@/assets/img/login/006.png')"
        alt=""
      />
    </div>
    <div>
      <img
        @click="download"
        class="download_btn"
        :src="require('@/assets/img/login/009.png')"
        alt=""
      />
    </div>
    <!-- <div class="download_btn" @click="download">点击下载</div> -->
  </div>
</template>
<script>
export default {
  data() {
    return {
      istips: false,
    };
  },
  mounted() {
    this.isWeiXin();
  },
  methods: {
    //点击下载
    download() {
      window.location.href = "http://app.allspark.top/app/allspark.apk";
    },
    isWeiXin() {
      var ua = window.navigator.userAgent.toLowerCase();
      // ua.indexOf('micromessenger')为真-微信端，如果为假，就是其他浏览器
      if (ua.indexOf("micromessenger") > -1) {
        this.istips = true; // 是微信端
      } else {
        this.istips = false;
      }
    },
  },
};
</script>
<style lang="scss" scoped>
.download_warp {
  background-image: url("../assets/img/login/007.png");
  background-repeat: repeat;
  background-size: 100% 100%;
  height: 100%;
  text-align: center;
}
.download_tips {
  text-align: right;
  margin: 0 10px !important;
  line-height: 26px;
  color: #999;
  font-size: 12px;
}
.download_logo {
  width: 70px;
  margin-top: 50px;
}
.download_title {
  font-size: 20px;
  margin: 5px 0;
}
.download_content {
  color: #666;
}
.download_btn {
  width: 120px;
  //   margin: 0 auto;
  //   background: #00b6ff;
  //   color: #fff;
  //   line-height: 30px;
  //   border-radius: 5px;
  margin-top: 40px;
}
.download_img {
  margin-top: 20px;
  width: 50%;
}
</style>